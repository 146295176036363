@font-face {
	font-family: "LindaleIcons";
	src: url("LindaleIcons.eot?f8446737efc93caa533b0737fce96eb5?#iefix") format("embedded-opentype"),
url("LindaleIcons.woff?f8446737efc93caa533b0737fce96eb5") format("woff"),
url("LindaleIcons.woff2?f8446737efc93caa533b0737fce96eb5") format("woff2"),
url("LindaleIcons.ttf?f8446737efc93caa533b0737fce96eb5") format("truetype"),
url("LindaleIcons.svg?f8446737efc93caa533b0737fce96eb5#LindaleIcons") format("svg");
}

.l-icon {
	line-height: 1;
}

.l-icon:before {
	font-family: LindaleIcons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.l-icon-3dbazaar:before {
	content: "\f101";
}
.l-icon-brush-plus:before {
	content: "\f102";
}
.l-icon-cube-corners-plus:before {
	content: "\f103";
}
.l-icon-cube-plus:before {
	content: "\f104";
}
.l-icon-curve:before {
	content: "\f105";
}
.l-icon-curve-exp:before {
	content: "\f106";
}
.l-icon-curve-plus:before {
	content: "\f107";
}
.l-icon-curve-handles-left:before {
	content: "\f108";
}
.l-icon-curve-handles-lock:before {
	content: "\f109";
}
.l-icon-curve-handles-right:before {
	content: "\f10a";
}
.l-icon-distribution:before {
	content: "\f10b";
}
.l-icon-distribution-plus:before {
	content: "\f10c";
}
.l-icon-empty-plus:before {
	content: "\f10d";
}
.l-icon-eyedropper:before {
	content: "\f10e";
}
.l-icon-eyedropper-plus:before {
	content: "\f10f";
}
.l-icon-gears:before {
	content: "\f110";
}
.l-icon-image-plus:before {
	content: "\f111";
}
.l-icon-list-plus:before {
	content: "\f112";
}
.l-icon-marker-multiple-plus:before {
	content: "\f113";
}
.l-icon-mask-area:before {
	content: "\f114";
}
.l-icon-mask-area-plus:before {
	content: "\f115";
}
.l-icon-mask-exclusive:before {
	content: "\f116";
}
.l-icon-mask-inclusive:before {
	content: "\f117";
}
.l-icon-material:before {
	content: "\f118";
}
.l-icon-random-transforms:before {
	content: "\f119";
}
.l-icon-path:before {
	content: "\f11a";
}
.l-icon-path-plus:before {
	content: "\f11b";
}
.l-icon-skatter:before {
	content: "\f11c";
}
.l-icon-spray-plus:before {
	content: "\f11d";
}
.l-icon-surface:before {
	content: "\f11e";
}
.l-icon-surface-plus:before {
	content: "\f11f";
}
.l-icon-teapot:before {
	content: "\f120";
}
.l-icon-zoom-extents:before {
	content: "\f121";
}
