.popup-overlay-title
{
    padding: 16px;
    font-size: 16px;
    font-weight: bold;
}

.popup-overlay-content {
    padding: 16px;
}

.popup-overlay-action {
    padding: 16px;
}